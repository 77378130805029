import ReactDOM from 'react-dom';
import {reportWebVitals} from './reportWebVitals';
import './Styles';
declare const Root: HTMLDivElement;

entry();

async function entry(): Promise<void> {
  const {App} = await import('./App');
  ReactDOM.render(<App />, Root);

  reportWebVitals();
}
